/* eslint-disable max-len */
import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const prListSnippet = require('raw-loader!../../../../data/snippets/accessibility-pr-list.example');
const jestAxeSnippet = require('raw-loader!../../../../data/snippets/accessibility-jest-axe.example');

const IndexPage = ({}) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Resources"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Resources"
      />

      <Section title="Learn More about Accessibility">
        <Paragraph>
          Learn more about accessible product development through these
          websites, blogs and educational materials:
        </Paragraph>
        <List type="unstyled">
          <li>
            <Link href="https://www.w3.org/TR/WCAG21">
              Web Content Accessibility Guidelines (WCAG) 2.1
            </Link>
            : the specific criteria to test for accessibility compliance.
          </li>
          <li>
            <Link href="https://www.a11yproject.com/">A11y Project</Link>:
            simplified WCAG explanations, lists, blogs, tools and other
            resources.
          </li>
          <li>
            <Link href="https://www.digitala11y.com/">Digital A11y</Link>:
            another source for WCAG explanations, including a blog and
            additional resources.
          </li>
          <li>
            <Link href="https://www.w3.org/WAI/courses/foundations-course/">
              W3C Digital Accessibility Foundations
            </Link>
            : free, online course to learn the basics of accessibility.
          </li>
        </List>
      </Section>

      <Section title="Building Accessible Products">
        <Paragraph>
          The{' '}
          <Link href="/guidelines/accessibility/checklists" isDesignCodeLink>
            Uniform Accessibility Checklist
          </Link>{' '}
          is our quick reference for designing any Hudl interface. The following
          tools are referenced in the checklist and elsewhere:
        </Paragraph>
        <List type="unstyled">
          <li>
            <Link href="https://storybook.js.org/blog/accessibility-testing-with-storybook/">
              Storybook Accessibility Add-on
            </Link>
            : use the panel to be sure no violations exist as you build (if
            using Storybook).
          </li>
          <li>
            <Link href="https://marketplace.visualstudio.com/items?itemName=deque-systems.vscode-axe-linter">
              Visual Studio Code axe Accessibility Linter extension
            </Link>
            : highlight accessibility issues in your markup as you code.
          </li>
          <li>
            <Link href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles">
              ARIA Roles
            </Link>
            : full list of available ARIA role attributes.
          </li>
          <li>
            <Link href="https://developer.mozilla.org/en-US/docs/web/Accessibility/ARIA/Attributes">
              ARIA States and Properties
            </Link>
            : full list of available ARIA states and properties.
          </li>
        </List>
      </Section>

      <Section title="Test for Accessibility">
        <Paragraph>
          Use these tools and checks to verify accessibility in your code before
          merging a pull request:
        </Paragraph>
        <List type="unstyled" className="uni-margin--one--bottom">
          <li>
            <Link href="https://github.com/YozhikM/stylelint-a11y#readme">
              stylelint-a11y extension
            </Link>
            : find accessibility issues in stylesheets (if using Stylelint).
          </li>
          <li>
            Accessibility PR Checklist: add the list below to your repo's
            PULL_REQUEST_TEMPLATE.md file.
          </li>
        </List>
        <CodeSnippet disableCodeEditing code={prListSnippet} />
        <List type="unstyled" className="uni-margin--one--bottom">
          <li>
            <Link href="https://github.com/nickcolley/jest-axe">
              jest-axe Plugin
            </Link>
            : add accessibility snapshot tests to your existing Jest unit tests
            to check for violations:
          </li>
        </List>
        <CodeSnippet disableCodeEditing code={jestAxeSnippet} />
      </Section>

      <Section title="Legal Requirements">
        <Paragraph>
          These are some of the legal requirements Hudl should be aware of:
        </Paragraph>
        <List type="unstyled">
          <li>
            <Link href="https://www.access-board.gov/ict/">
              ADA Section 508
            </Link>
            : U.S. regulations related to accessibility.
          </li>
          <li>
            <Link href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32019L0882">
              European Accessibility Act
            </Link>
            : E.U. regulations related to accessibility.
          </li>
          <li>
            <Link href="https://www.legislation.gov.uk/ukpga/2010/15/contents">
              Equality Act 2010
            </Link>
            : U.K. regulations related to accessibility.
          </li>
          <li>
            <Link href="https://sync.hudlnet.com/display/UNIFORM/2022/01/21/Hudl%2C+Uniform%2C+and+Accessibility">
              Disability Discrimination Act of 1992
            </Link>
            : Australian regulations related to accessibility.
          </li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
